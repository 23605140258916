<template>
    <BreadcrumbVue />
    <section class="relatorios">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 mt-3" v-for="(relatorio, index) in relatorios" :key="index">
                    <div class="box-relatorios">
                        <div class="titulo-relatorios">
                            <h6>
                                {{ relatorio.ano }}
                            </h6>
                        </div>

                        <div class="nome-relatorios">
                            <span>
                                {{ relatorio.titulo }}
                            </span>
                        </div>

                        <div class="link-download mt-4 mb-3">
                            <a :href="relatorio.arquivo" aria-label="Download de arquivo" target="_blank">
                                Ver Relatório
                                <i class="bi bi-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <svg xmlns="http://www.w3.org/2000/svg" width="1411" height="216" viewBox="0 0 1411 216" fill="none"
        preserveAspectRatio="none">
        <path
            d="M-1.63184 215.837L1412.09 215.837L1412.09 200.647C952.93 -59.9253 493.774 -71.6101 -1.63184 200.647L-1.63184 215.837Z"
            fill="#EEEEEE" fill-opacity="0.8" />
    </svg>
    <Popup />
    <FooterInternas />
</template>

<script>
import axios from 'axios';

const urlrelatorios = 'https://api.cref20.org.br/wp-json/cref/v1/relatorios';

import FooterInternas from '../components/FooterInternas/FooterInternas.vue';
import BreadcrumbVue from '@/components/Breadcrumb/Breadcrumb.vue';
import Popup from '@/components/Popup/PopupComponent.vue';
export default {
    name: 'RelatoriosPage',
    components: {
        FooterInternas,
        BreadcrumbVue,
        Popup
    },
    data() {
        return {
            relatorios: []
        }
    },
    mounted() {
        axios.get(urlrelatorios).then((response) => {
            this.relatorios = response.data[0].arquivos.map((arquivo) => ({
                ano: arquivo.ano_arquivo,
                titulo: arquivo.titulo_arquivo,
                arquivo: arquivo.arquivo
            }))
        })
    }
}
</script>

<style scoped>
svg {
    width: 100%;
}

.relatorios .box-relatorios {
    border: 1px solid #fcd2d2;
    padding: 15px;
    border-radius: 10px;
}

.relatorios .box-relatorios .titulo-relatorios h6 {
    color: #45A047;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.relatorios .box-relatorios .nome-relatorios span {
    color: #575757;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.relatorios .box-relatorios .link-download a {
    color: #5e5e5e;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
    border-bottom: 1px solid #5D5D5D;
}

.relatorios .box-relatorios .link-download a .bi-arrow-right {
    color: #45A047;
}

@media (max-width: 765px) {
    svg {
        display: none;
    }
}
</style>