<template>
    <BreadcrumbVue />
    <section class="legislacao">
        <div class="container">
            <div class="row mt-5">
                <div class="filtro">
                    <ul class="link-filtro" role="list">
                        <li role="listitem">
                            <span>
                                Selecione uma categoria:
                            </span>
                        </li>
                        <li role="listitem">
                            <a href="javascript:;" @click="selected = 'todos'" :class="{ active: selected === 'todos' }">
                                Todos
                            </a>
                        </li>
                        <li role="listitem" v-for="(lei, index) in leis" :key="index">
                            <a href="javascript:;" @click="selected = lei.tipo_da_lei"
                                :class="{ active: selected === lei.tipo_da_lei }">
                                {{ lei.tipo_da_lei }}
                            </a>
                        </li>
                    </ul>

                    <hr>
                </div>

                <div class="leis" v-for="(lei, index) in leis" :key="index" v-show="selected === 'todos'">
                    <div class="row campos-filtrados">
                        <div class="col-lg-4">
                            <h2>
                                {{ lei.tipo_da_lei }}
                            </h2>
                        </div>

                        <div class="col-lg-8">
                            <div class="box-leis" v-for="lei_info in lei.leis_info" :key="lei_info.nome_lei">
                                <div class="titulo-leis">
                                    <h6>
                                        {{ lei_info.nome_lei }}
                                    </h6>
                                </div>
                                <div class="resumo-leis">
                                    <p>
                                        {{ lei_info.resumo_lei }}
                                    </p>
                                </div>
                                <div class="link-leis">
                                    <a :href="lei_info.link_lei" aria-label="Label">
                                        Acessar
                                        <i class="bi bi-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                </div>
                <div class="leis" v-for="(lei, index) in leis" :key="index" v-show="selected === lei.tipo_da_lei">
                    <div class="row campos-filtrados">
                        <div class="col-lg-4">
                            <h2>
                                {{ lei.tipo_da_lei }}
                            </h2>
                        </div>

                        <div class="col-lg-8">
                            <div class="box-leis" v-for="lei_info in lei.leis_info" :key="lei_info.nome_lei">
                                <div class="titulo-leis">
                                    <h6>
                                        {{ lei_info.nome_lei }}
                                    </h6>
                                </div>
                                <div class="resumo-leis">
                                    <p>
                                        {{ lei_info.resumo_lei }}
                                    </p>
                                </div>
                                <div class="link-leis">
                                    <a :href="lei_info.link_lei" aria-label="Label">
                                        Acessar
                                        <i class="bi bi-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                </div>
            </div>
        </div>
    </section>

    <svg xmlns="http://www.w3.org/2000/svg" width="1411" height="216" viewBox="0 0 1411 216" fill="none"
        preserveAspectRatio="none">
        <path
            d="M-1.63184 215.837L1412.09 215.837L1412.09 200.647C952.93 -59.9253 493.774 -71.6101 -1.63184 200.647L-1.63184 215.837Z"
            fill="#EEEEEE" fill-opacity="0.8" />
    </svg>
    <Popup />
    <FooterInternas />
</template>

<script>
import FooterInternas from '../components/FooterInternas/FooterInternas.vue';
import BreadcrumbVue from '../components/Breadcrumb/Breadcrumb.vue';

import axios from 'axios';
import Popup from '@/components/Popup/PopupComponent.vue';

const URLLEGISLACAO = 'https://api.cref20.org.br/wp-json/cref/v1/legislacao';

export default {
    name: 'LegislacaoPage',
    components: {
        FooterInternas,
        BreadcrumbVue,
        Popup
    },
    data() {
        return {
            leis: [],
            selected: 'todos'
        }
    },
    mounted() {
        axios.get(URLLEGISLACAO).then((response) => {
            this.leis = response.data[0].corpo.map((lei) => ({
                tipo_da_lei: lei.tipo_da_lei,
                leis_info: lei.leis.map((lei_info) => ({
                    nome_lei: lei_info.nome_da_lei,
                    resumo_lei: lei_info.resumo_da_lei,
                    link_lei: lei_info.link_da_lei
                }))
            }))
        });
    }
}
</script>

<style scoped>
svg {
    width: 100%;
}

.campos-filtrados h2 {
    color: #12575F;
    font-family: 'Roboto';
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.legislacao .filtro .link-filtro {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    list-style-type: none;
}

.legislacao .filtro .link-filtro li {
    margin: 10px;
}

.legislacao .filtro .link-filtro li span {
    color: #575757;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.legislacao .filtro .link-filtro li a {
    text-decoration: none;
    color: #12575F;
    padding: 10px;
    border-radius: 10px;
    background: #E7E7E7;
}

.legislacao .filtro .link-filtro li a:hover {
    color: #fff;
    background: #12575F;
    transition: .3s;
}

.legislacao .filtro .link-filtro li a.active {
    color: #fff;
    background: #12575F;
}

.legislacao .box-leis {
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
    padding: 20px;
    border-radius: 10px;
}

.legislacao .box-leis .titulo-leis h6 {
    color: #12575F;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
}

.legislacao .box-leis .resumo-leis {
    color: #575757;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.legislacao .box-leis .link-leis a {
    color: #5e5e5e;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
    border-bottom: 1px solid #5D5D5D;
}

.legislacao .box-leis .link-leis a .bi-arrow-right {
    color: #45A047;
}

@media (max-width: 765px) {
    svg {
        display: none;
    }
}
</style>