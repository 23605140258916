<template>

    <Noticias />
    <!-- <VideosVue/> -->
    <Multimidia />
    <Popup />
    <FooterInternas />
</template>

<script>

import FooterInternas from '../components/FooterInternas/FooterInternas.vue';
import Noticias from '../components/Noticias/Noticias.vue';
import Multimidia from '../components/Multimidia/Multimidia.vue';
import Popup from '@/components/Popup/PopupComponent.vue';
// import VideosVue from '../components/Videos/Videos.vue';

export default {
    name: 'NoticiasPage',
    components: {
        FooterInternas,
        Noticias,
        Multimidia,
        Popup
        // VideosVue
    },
    watch: {
        $route() {
            this.scrollTop();
        }
    },
    methods: {
        scrollTop() {
            window.scrollTo(0, 0);
        }
    },
    created() {
        this.scrollTop();
    }
}
</script>