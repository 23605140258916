<template>
    <BreadcrumbVue />
    <section class="sobre-page">
        <div class="container">
            <div class="row my-5">
                <div class="col-lg-12">
                    <div class="titulo-page">
                        <h2>
                            {{ sobre.titulo_sobre }}
                        </h2>
                    </div>
                </div>
                <div class="row text-center">
                    <div class="col-lg-6" v-for="(sobreImgs, index) in sobre.galeria_sobre" :key="index">
                        <img :src="sobreImgs" alt="Exemplo">
                    </div>
                </div>

                <div class="col-lg-12">
                    <p class="text-sobre my-5" v-html="sobre.texto_sobre"></p>

                    <img :src="sobre.imagem_sobre" alt="Exemplo 2" v-if="sobre.imagem_sobre != ''">
                </div>
            </div>
        </div>
    </section>

    <svg xmlns="http://www.w3.org/2000/svg" width="1411" height="216" viewBox="0 0 1411 216" fill="none"
        preserveAspectRatio="none">
        <path
            d="M-1.63184 215.837L1412.09 215.837L1412.09 200.647C952.93 -59.9253 493.774 -71.6101 -1.63184 200.647L-1.63184 215.837Z"
            fill="#EEEEEE" fill-opacity="0.8" />
    </svg>
    <Popup />
    <FooterInternas />
</template>

<script>
import axios from 'axios';

const urlSOBRE = 'https://api.cref20.org.br/wp-json/cref/v1/sobre';

import FooterInternas from '../components/FooterInternas/FooterInternas.vue';
import BreadcrumbVue from '../components/Breadcrumb/Breadcrumb.vue';
import Popup from '@/components/Popup/PopupComponent.vue';
export default {
    name: 'SobrePage',
    components: {
        FooterInternas,
        BreadcrumbVue,
        Popup
    },
    watch: {
        $route() {
            this.scrollTop()
        }
    },
    data() {
        return {
            sobre: []
        }
    },
    methods: {
        scrollTop() {
            window.scrollTo(0, 0)
        }
    },
    mounted() {
        axios.get(urlSOBRE).then((response) => {
            this.sobre = {
                titulo_sobre: response.data[0].corpo.titulo_sobre,
                imagem_sobre: response.data[0].corpo.imagem_sobre,
                texto_sobre: response.data[0].corpo.texto_sobre,
                galeria_sobre: response.data[0].corpo.galeria_sobre
            };
        })
    },
    created() {
        this.scrollTop()
    }
}
</script>

<style scoped>
.text-sobre {
    color: #313131;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.sobre-page .titulo-page h2 {
    color: #313131;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.sobre-page .container .row.center {
    justify-content: center;
}

.sobre-page img {
    width: 100%;
}

svg {
    width: 100%;
}

@media (max-width: 765px) {
    svg {
        display: none;
    }

    .text-sobre {
        text-align: justify;
        padding: 10px;
    }
}
</style>