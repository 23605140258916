<template>
    <section class="primeiro-registro">
        <div class="container">
            <div class="row" v-for="(pRegistro, index) in pRegistro" :key="index">
                <div class="text-center my-5">
                    <h2>
                        Registro Novo
                    </h2>
                </div>
                <div class="texto-registro">
                    <p v-html="pRegistro.conteudo"></p>
                </div>
            </div>
        </div>
    </section>
    <Popup />
    <FooterInternas />
</template>
<script>
import FooterInternas from '@/components/FooterInternas/FooterInternas.vue';
import Popup from '@/components/Popup/PopupComponent.vue';
import axios from 'axios';
const URL = 'https://api.cref20.org.br/wp-json/cref/v1/primeiro-registro';
export default {
    name: 'PrimeiroRegistro',
    data() {
        return {
            pRegistro: []
        }
    },
    components: {
        FooterInternas,
        Popup
    },
    mounted() {
        axios.get(URL).then(response => {
            this.pRegistro = response.data.map(registro => ({
                conteudo: registro.conteudo
            }))
        })
    }
}
</script>

<style scoped></style>