<template>
    <BreadcrumbVue />
    <section class="fiscalizacao">
        <div class="container">
            <div class="row" v-for="(imp, index) in impDados" :key="index">
                <div class="titulo-page my-4">
                    <h2>
                        {{ imp.titulo }}
                    </h2>
                </div>
                <div class="texto-fiscalizacao">
                    <p v-html="imp.texto"></p>
                </div>
            </div>
        </div>
    </section>
    <Popup />
    <FooterInternas />
</template>

<script>
import axios from 'axios';
import FooterInternas from '../components/FooterInternas/FooterInternas.vue';
import BreadcrumbVue from '@/components/Breadcrumb/Breadcrumb.vue';
import Popup from '@/components/Popup/PopupComponent.vue';

const URLIMP = 'https://api.cref20.org.br/wp-json/cref/v1/justificativas';

export default {
    name: 'OrientacoesPage',
    components: {
        FooterInternas,
        BreadcrumbVue,
        Popup
    },
    data() {
        return {
            impDados: []
        }
    },
    mounted() {
        axios.get(URLIMP).then((response) => {
            this.impDados = response.data.map((impdado) => ({
                titulo: impdado.corpo.titulo_pagina,
                texto: impdado.corpo.texto_pagina
            }))
        })
    }
}
</script>