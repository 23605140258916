<template>

    <section class="multimidia-interna">
        <div class="container">
            <div class="row">
                <div class="titulo-sec my-4" v-for="(titulos, index) in multimidia" :key="index">
                    <h2>
                        {{ titulos.titulo }}
                    </h2>
                </div>

                <div class="carousel">
                    <div id="carouselExample" class="carousel slide">
                        <div class="carousel-inner" v-for="(imagens, index) in multimidia" :key="index">
                            <div class="carousel-item" v-for="(imagem, indexImg) in imagens.galeria_de_fotos"
                                :key="indexImg" :class="{ active: indexImg === 0 }">
                                <img :src="imagem.imagem" class="d-block w-100" alt="Imagem">
                            </div>
                        </div>
                        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExample"
                            data-bs-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next" type="button" data-bs-target="#carouselExample"
                            data-bs-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>

                <div class="col-lg-12">
                    <div class="box-compartilhar my-4">
                        <ul class="list-redes" role="list">
                            <li role="listitem" class="titulo-list">
                                Compartilhar esse post:
                            </li>
                            <li role="listitem">
                                <a :href="'https://www.facebook.com/sharer/sharer.php?&amp ; u='+ this.urlFinal"
                                    target="_blank">
                                    <img :src="require('@/assets/imgs/icon-facebook.png')" alt="FB Icon">
                                </a>
                            </li>
                            <li role="listitem">
                                <a :href="'https://twitter.com/home?' + this.urlFinal" target="_blank">
                                    <i class="bi bi-twitter"></i>
                                </a>
                            </li>
                            <li role="listitem">
                                <a :href="'https://wa.me/?text=' + this.urlFinal" target="_blank">
                                    <i class="bi bi-whatsapp"></i>
                                </a>
                            </li>
                            <li role="listitem">
                                <a :href="'mailto:?' + this.urlFinal" target="_blank">
                                    <i class="bi bi-envelope"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <NoticiasRelacionadas />
    <Popup />
    <FooterInternasVue />
</template>

<script>
import axios from 'axios';

import NoticiasRelacionadas from '@/components/NoticiasRelacionadas/NoticiasRelacionadas.vue'
import FooterInternasVue from '@/components/FooterInternas/FooterInternas.vue';
import Popup from '@/components/Popup/PopupComponent.vue';

export default {
    name: 'MultimidiaLeitura',
    components: {
        FooterInternasVue,
        NoticiasRelacionadas,
        Popup
    },
    data() {
        return {
            multimidia: [],
            urlFinal: ''
        }
    },
    watch: {
        $route() {
            this.fetchDadosMult();
            this.scrollTop();
        }
    },
    methods: {
        fetchDadosMult() {
            const MULTIMIDIA = 'https://api.cref20.org.br/wp-json/cref/v1/multimidia/' + this.$route.params.multimidia;

            axios.get(MULTIMIDIA).then((response) => {
                this.multimidia = response.data.map((mult) => ({
                    titulo: mult.titulo,
                    galeria_de_fotos: mult.galeria_de_fotos.map((imagem) => ({
                        imagem: imagem
                    }))
                }))
            })
        },

        scrollTop() {
            window.scrollTo(0, 0);
        }
    },
    created() {
        this.urlFinal = window.location.href;
        this.fetchDadosMult();
        this.scrollTop();
    }
}
</script>

<style scoped>
.titulo-sec {
    color: #393939;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
}

.carousel img {
    height: 600px;
    object-fit: cover;
    border-radius: 40px;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
    background-color: #45A047;
    padding: 25px;
    border-radius: 50px;
}

.box-compartilhar .list-redes a {
    height: 70px;
    width: 70px;
    border: 1px solid #D1D1D1;
    padding: 20px;
    border-radius: 50px;
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;
}

.redes-sociais li a i {
    color: #45A047;
}

.box-compartilhar {
    border-radius: 10px;
    background: #F1F1F1;
    padding: 20px;
}

.box-compartilhar .list-redes {
    display: flex;
    list-style: none;
    align-items: center;
    margin-top: 10px;
    padding: 0;
}

.box-compartilhar .list-redes li .titulo-list {
    color: #747474 !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
}

.box-compartilhar .list-redes li {
    margin: 0 10px;
}

.box-compartilhar .list-redes a {
    height: 50px;
    width: 50px;
}

.box-compartilhar .list-redes li a i {
    color: #45A047;
}
</style>