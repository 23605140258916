<template>
    <section class="naoEncontrada">
        <div class="container">
            <div class="row">
                <h1>
                    404
                </h1>
                <h2>
                    Página não encontrada!
                </h2>
            </div>
        </div>
    </section>
    <Popup />
    <FooterInternasVue />
</template>

<script>
import FooterInternasVue from '@/components/FooterInternas/FooterInternas.vue';
import Popup from '@/components/Popup/PopupComponent.vue';
export default {
    name: 'NaoEncontrada',
    components: {
        FooterInternasVue,
        Popup
    }
}
</script>