<template>
    <section class="boletos">
        <div class="container">
            <div class="row" v-for="(link, index) in links" :key="index">
                <div class="col-lg-12">
                    <div class="text-center my-5">
                        <h2>
                            Pague a sua anuidade 2025 aqui
                        </h2>
                    </div>
                </div>
                <div class="col-lg-6 mt-3 text-center">
                    <a :href="link.link_pf">
                        Pessoa Física
                    </a>
                </div>
                <div class="col-lg-6 mt-3 text-center">
                    <a :href="link.link_pj">
                        Pessoa Jurídica
                    </a>
                </div>
            </div>
        </div>
    </section>
    <Popup />
    <FooterInternas/>
</template>

<script>
import FooterInternas from '@/components/FooterInternas/FooterInternas.vue';
import Popup from '@/components/Popup/PopupComponent.vue';

import axios from 'axios';

const URL = 'https://api.cref20.org.br/wp-json/cref/v1/boleto-anuidade';
export default {
    name: 'BoletoAnuidadeVue',
    components: {
        FooterInternas,
        Popup
    },
    data() {
        return {
            links: []
        }
    },
    mounted() {
        axios.get(URL).then(response => {
            this.links = response.data.map(link => ({
                link_pf: link.link_pessoa_fisica,
                link_pj: link.link_pessoa_juridica
            })) 
        })
    }
}
</script>
<style scoped>
    .boletos a {
        width: 100%;
        background-color: #45A047;
        display: flex;
        border-radius: 10px;
        height: 34px;
        align-items: center;
        justify-content: center;
        color: #fff;
        text-decoration: none;
    }
</style>