<template>
    <section class="politica">
        <div class="container">
            <div class="row" v-for="(politica, index) in politica" :key="index">
                <div class="col-lg-12 my-5">
                    <div class="text-left">
                        <h2>
                            Política de Privacidade – Conselho Regional de Educação Física 20ª Região/SE
                        </h2>
                    </div>
                </div>
                <div class="col-lg-12">
                    <p v-html="politica.conteudo"></p>
                </div>
            </div>
        </div>
    </section>
    <Popup />
    <FooterInternas />
</template>
<script>
import axios from 'axios';
import FooterInternas from '@/components/FooterInternas/FooterInternas.vue';
import Popup from '@/components/Popup/PopupComponent.vue';

const URL = 'https://api.cref20.org.br/wp-json/cref/v1/politica-de-privacidade';
export default {
    name: 'PolticaDePrivacidadeVue',
    components: {
        FooterInternas,
        Popup
    },
    data() {
        return {
            politica: []
        }
    },
    mounted() {
        axios.get(URL).then(response => {
            this.politica = response.data.map(politica => ({
                conteudo: politica.conteudo
            }))
        })
    }
}
</script>
<style scoped></style>