<template>
    <div v-if="showPopup" class="popup-overlay">
        <div class="popup-content">
            <h2>{{ popup.title.rendered }}</h2>

            <a :href="`/${popup.acf.pagina_de_destino}`" target="_blank">
                <div v-for="(conteudo, index) in popup.acf.conteudos" :key="index">
                    <div v-if="conteudo.acf_fc_layout === 'imagem_destaque'">
                        <img :src="conteudo.imageUrl" alt="Imagem de Destaque" class="popup-image" />
                    </div>

                    <div v-if="conteudo.acf_fc_layout === 'editor_de_texto'">
                        <div v-html="conteudo.conteudo_do_popup"></div>
                    </div>
                </div>
            </a>

            <button @click="closePopup" class="close-btn">+</button>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    data() {
        return {
            popup: null,
            showPopup: false,
        };
    },
    async mounted() {
        const currentPath = window.location.pathname;

        try {
            const response = await axios.get("https://api.cref20.org.br/wp-json/wp/v2/popup");
            const popups = response.data;

            // Filtra o popup configurado para a página atual
            const matchingPopup = popups.find((popup) => {
                const paginasPermitidas = popup.acf.paginas_permitidas;
                return (
                    paginasPermitidas &&
                    paginasPermitidas.some((pagina) => pagina.slug_da_pagina === currentPath)
                );
            });

            if (matchingPopup) {
                this.popup = matchingPopup;
                await this.loadImageUrls();
                this.showPopup = true; // Exibe o popup
                document.body.style.overflow = "hidden"; // Travar rolagem
            }
        } catch (error) {
            console.error("Erro ao buscar popups:", error);
        }
    },
    methods: {
        closePopup() {
            this.showPopup = false;
            document.body.style.overflow = "auto"; // Restaurar rolagem
        },
        async getImageUrl(imagemID) {
            const url = `https://api.cref20.org.br/wp-json/wp/v2/media/${imagemID}`;
            try {
                const response = await axios.get(url);
                return response.data.guid.rendered;
            } catch (error) {
                console.error('Erro ao buscar a imagem:', error);
                return ''; // Retorna uma string vazia se houver erro
            }
        },
        async loadImageUrls() {
            const promises = this.popup.acf.conteudos.map(async (conteudo) => {
                if (conteudo.acf_fc_layout === 'imagem_destaque') {
                    try {
                        const imageUrl = await this.getImageUrl(conteudo.imagem); // Aqui deve vir o ID da imagem
                        if (imageUrl) {
                            // Força a atualização reativa no Vue
                            conteudo.imageUrl = imageUrl; // Atualiza reativamente a URL da imagem
                        }
                    } catch (error) {
                        console.error('Erro ao carregar a URL da imagem:', error);
                    }
                }
            });

            // Espera que todas as promessas de carregamento de imagem sejam resolvidas
            await Promise.all(promises);
        }
    },
};
</script>

<style scoped>
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999999;
}

.popup-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: relative;
    max-width: 780px;
}

.popup-image {
    width: 640px;
}

.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #f44336;
    /* Leve tom de vermelho */
    color: #fff;
    border: none;
    width: 28px;
    height: 28px;
    border-radius: 28px;
    /* Botão arredondado */
    font-size: 24px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
}

.close-btn:hover {
    background-color: #e53935;
    /* Cor de hover levemente mais escura */
}

.close-btn:focus {
    outline: none;
    /* Remove outline padrão */
}

.close-btn {
    transform: rotate(45deg);
    /* Gira o botão em 45 graus */
}

/* TABLET */
@media (min-width: 766px) and (max-width: 1360px) {
    .popup-image {
        width: 448px;
    }
}

@media (max-width: 765px) {
    .popup-image {
        width: 480px;
    }
}
</style>